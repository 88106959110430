import React from "react";
import "../careers.scss";
import { image } from "../../../assets/exports/image.js";


const Mainsection = () => (
    <>
        <div className="main-section">
            <div className="main-section-container">
                <img src={image.career} className="img" alt="codeVillage technology" />
                <div className="section-right-container">
                    <div className="title-container">
                        <span className="title">Careers</span>
                    </div>
                    <div className="body-container">
                        <h2 className="body-content">
                            We are looking for a creative, enthusiastic, self-motivated and unique personality who can easily adapt to 
                            <span className="highlight"> our growing corporate culture!</span>
                        </h2>
                    </div>
                    <div className="subbody-container">
                        <p className="subbody-content">
                            Code Village is here to provide you a learning platform not only on technical aspects but also on emotional and social arenas. We invite you to be a part of ouramazing success story through exciting growth opportunities. Our company can proudly say that we as employers feel employees’ as valuable assets, who are provided guidance and support in a dynamic environment.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        {/* <div className="test">hello</div> */}
    </>
);

export default Mainsection;