import React from 'react'
import Card from "./Card.js"
import "../careers.scss"

const vacany = () => {
  return (
    <>
        <div className='vacancy-section'>
            <div className='join-us'>
              <span className='join-us-content'>
                let's join us
                </span>
            </div>
            <div className='vacan-heading-container'>
              <h1 className='vacan-head'>our <span className='head-hglt'>job vacancy</span></h1>
            </div>
            <div className='card-wrapper'>
              <Card/>
            </div>
        </div>
    </>
    
  )
}

export default vacany