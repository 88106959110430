import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./index.scss";
import Web from "../../components/Service_pages/WebDev/Web";
import Nav from "../../components/Service_pages/Nav/Nav";
import Header from "../../components/Service_pages/Header/Header";

const Services = () =>{
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
    <>
    <Navbar/>
    <Header/>   
    <Nav/>
    <Web/> 
    <Footer/>

    </>
    )
};

export default Services;