import React from 'react'
import "../../pages/Contact/contact.scss";
import { VscAccount, VscMail, VscComment, VscVmActive } from "react-icons/vsc";
import { SlPhone } from "react-icons/sl";
import profile from "../../assets/images/icons/profile.svg";
import email from "../../assets/images/icons/Email.svg";
import messageus from "../../assets/images/icons/Message us.svg";
import serviceicon from "../../assets/images/icons/Services.svg";
import phoneicon from "../../assets/images/icons/phone.svg";


export default function Form() {
    return (
        <div className='form'>
            <form id='contact_form' className='c_form'>
                <div className='input_container'>
                    <img src={profile} alt='icon' className='form_icons'/>
                    <input placeholder="Name" className='form_inputs' required />
                </div>
                <div className='input_container'>
                <img src={email} alt='icon' className='form_icons'/>
                    <input placeholder='Email' className='form_inputs' required />
                </div>
                <div className='input_container'>
                <img src={phoneicon} alt='icon' className='form_icons'/>
                    <input placeholder='Phone Number' className='form_inputs' />
                </div>
                <div className='input_container'>
                <img src={serviceicon} alt='icon' className='form_icons littledim'/>
                    <select className='form_inputs form_optn_show'>
                        <option value="Option_1" className='form_optn'>Interested in Code Village Services</option>
                        <option value="Option_2" className='form_optn'>I want to offer my services to Code Village </option>
                        <option value="OPtion_3" className='form_optn'>Partnership proposal/request</option>
                        <option value="Option_4" className='form_optn'>App Development Teams</option>
                        <option value="Option_5" className='form_optn'>Web Design Teams </option>
                        <option value="OPtion_6" className='form_optn'>Content Platforms</option>
                        <option value="Option_5" className='form_optn'>Digital Products</option>
                        <option value="OPtion_6" className='form_optn'>Others</option>
                    </select>
                </div>
                <div className='input_container'>
                <img src={messageus} alt='icon' className='form_icons'/>
                    <textarea placeholder='Message Us' className='form_inputs form_text' name='userMessage' />
                </div>
                <button>
                    Send Message
                </button>
            </form>

        </div>
    )
}
