import React from 'react'

const Header = () => {
  return (
    <div className="services_wrapper">
    <div className="services_head_wrapper">
        <h4>Service We Deliver</h4>
        <h2>innovative IT solution for your business demand</h2>
        <p>Code Village offers their services to fulfil the Web Development & Advertising needs of a business or individual. We have a team of skilled and trained professionals who can provide you with the best in class services.</p>
    </div>
    </div>
  )
}

export default Header