import React from 'react'
import { appcard } from '../../../data/info'
import "./index.scss"

const Card = () => {
  return (
    <>
        {appcard.map(({title, text, image})=>(
            <div className="card_container_app">
                <div className='image'>
                    <img src={image} alt ="codevilage"/>
                </div>
                <h1>
                    {title}
                </h1>
                <p>
                    {text}
                </p>

            </div>
       ))}
    </>
    
  )
}

export default Card