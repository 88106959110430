import React from "react";
import DashLayout from "../../../Layouts/DashLayout/DashLayout";

function Dashboard(props) {
  return (
    <DashLayout>
      <div className="dashbody">
        <div className="dashbody-title">Profile Details</div>
        Content goes here
      </div>
    </DashLayout>
  );
}

export default Dashboard;
