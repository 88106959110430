import React from "react";
import "./dashlayout.scss";
import AdminSideBar from "../../components/Admin/AdminSideBar/AdminSideBar";
import AdminHeader from "../../components/Admin/AdminHeader/AdminHeader";

function DashLayout(props) {
  const { children } = props;
  return (
    <div className="dashboard_layout">
      <div className="dash_sidebar">
        <AdminSideBar />
      </div>
      <div className="dash_right">
        <div className="dr_top">
          <AdminHeader />
        </div>
        <div className="dr_bottom">{children}</div>
      </div>
    </div>
  );
}

export default DashLayout;
