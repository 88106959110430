import React from 'react'
import './index.scss'
export default function Project(props) {
    return (
        <>
            <div className="project">
                <div className= {props.color}>
                    <img src= {props.image} alt="" />
                </div>
                <div className="project-name">
                    <h2>{props.name}</h2>
                </div>
                <div className="project-number">
                    <h2>{props.number}</h2>
                </div>
            </div>
        </>
    )
}
