import React from "react";
import { ucard_mini_data } from "../../../data/info";
const Ucardmini = () => {
  return (
    <>
      {ucard_mini_data.map(({ title, text, image }) => (
        <div className="ucard_mini_container">
          <div className="image_container">
            <img src={image} alt="Codevillage||UIUX design" />
          </div>
          <div className="ucard_contain">
            <h3>{title}</h3>
            <p>{text}</p>
          </div>
        </div>
      ))}
    </>
  );
};

export default Ucardmini;
