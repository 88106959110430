export const graphics_data=[
    {
        tag:'Graphic design helps to build your brand',
    },
    {
        tag:'Unique design makes your data impactful',
    },
    {
        tag:'Design creates effective communication with viewers',
    },
    {
        tag:'Creative design helps to win customers',
    },
]