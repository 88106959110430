import React from 'react';
import "./index.scss";


function Getincards(props) {
  return (
    <>
    <div className='g_card'>
        <div className='img_container' id ={props.id}>
        <img src={props.img} alt="codevillage||Best IT solution"/>
        </div>
        <h3 className='g_card_head'>{props.heading}</h3>
        <h5 className='g_card_desc'>{props.para}</h5>
        <h5 className='g_card_cont'>{props.cont}</h5>
    </div>
    </>
  )
}

export default Getincards;