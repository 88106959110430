import React, {useEffect} from 'react';
import "./index.scss"
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import Getincards from './Getincards';
import { image } from "../../assets/exports/image";
import Form from '../../components/Form/Form';

const GetinTouch = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <Navbar />
            <section className='getinwrapper'>
                <div className='getin_head'>
                    <h1>
                        Get in touch
                    </h1>
                    <p>Explain Your Project Below And We Will Come Back To You In Less Than One Hour!</p>
                </div>
                <div className='getin_body_container'>
                    <Getincards id="first" img={image.call} heading="Call Any Time" para="Call us if your question requires an immediate response." cont="9869482916 / 9822824664" />
                    <Getincards id="second" img={image.loaction} heading="Drop In Us" para="You can meet us at our office and discuss the details of your question." cont="Gatthaghar,Bhaktapur" />
                    <Getincards id="third" img={image.mail} heading="Email Us" para="Ask us a question by email and we will respond within a few days." cont="codevillage2079@.com" />
                </div>
            </section>
            <section className='form_section'>
                <div className='form_container'>
                    <div className='col-1'>
                        <div className='col_1_wrapper'>
                            <h1>
                                Let’s start a project together...
                            </h1>
                            <img src={image.getintouch} alt="codeVillage||web and software" />
                        </div>

                    </div>
                    <div className='col-2'>
                        <div className='col_2_wrapper'>
                            <h2>
                                Discuss your latest project
                                with a Code Village specialist.
                            </h2>
                            <p>
                                Let’s make your ideas happen.
                            </p>
                            <Form />
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
};

export default GetinTouch;