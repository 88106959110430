import React from "react";
import "./index.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { testimony } from "../../data/info";
import { useRef } from "react";
import leftArrow from "../../assets/images/left-arrow.svg";
import rightArrow from "../../assets/images/right-arrow.svg";
const Testimonial = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    className: "slider",
    draggable: true,
  };
  const slider = useRef(null);
  const scrollToPrev = () => {
    slider?.current?.slickPrev();
  };
  const scrollToNext = () => {
    slider?.current?.slickNext();
  };
  return (
    <>
      <Slider ref={slider} {...settings}>
        {testimony.map((item, index) => {
          return (
            <div className="testimonial-container" key={index}>
              <div className="testimonial-container-image">
                <img src={item.image} alt="" />
              </div>
              <div className="testimonial-container-info">
                <h2>{item.tname}</h2>
                <p>{item.job}</p>
              </div>
              <div className="testimonial-container-main">
                <p>&quot;{item.testimony}&quot;</p>
              </div>
              <div className="testimonial-index">
                <h3>{item.id}</h3>
              </div>
            </div>
          );
        })}
      </Slider>
      <div className="arrows-container">
        <button className="previousButton" onClick={scrollToPrev}>
          <img src={leftArrow} alt="" />
        </button>
        <button className="nextButton" onClick={scrollToNext}>
          <img src={rightArrow} alt="" />
        </button>
      </div>
    </>
  );
};

export default Testimonial;

/*

export default function Testimonial() {
    return (
        <>
            {
                testimony.map(

                    ({ id, image, tname, job, testimony }) => (
                        <div className='testmony_wrap'>
                            <h1 className='testimony_id'>
                                {id}
                            </h1>
                            <div className='testimony_detail'>
                                <div className='testimony_img'>
                                    <img src={image} alt='codeVillage' />
                                </div>
                                <h4 className='testimony_name'>
                                    {tname}
                                </h4>
                                <h5 className='testimony_postion'>
                                    {job}
                                </h5>
                                <p className='testomony_contain'>
                                    {testimony}
                                </p>
                            </div>

                        </div>
                    )



                )
            }
        </>
    )
}
*/
