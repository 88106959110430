import React from "react";
import Navbar from "../../Navbar/Navbar";
import Header from "../Header/Header";
import Nav from "../Nav/Nav";
import Footer from "../../Footer/Footer";
import { image } from "../../../assets/exports/image";
import "./index.scss";

const Digimarking = () => {
  return (
    <>
      <Navbar />
      <Header />
      <Nav />

      {/* section starts from here  */}
      <div className="dsection_top">
        <div className="dsection_left">
          <img
            src={image.digimarktop}
            alt="Codevillage||Software Development||Webdevelopment||UIUX design"
          />
        </div>
        <div className="dsection_right">
          <h1>Reach Your Potential Customer</h1>
          <p>
            Digital marketing is a set of marketing strategies used to reach,
            attract, and retain digital customers. The definition can vary, but
            most experts consider these tactics forms of online advertising or
            internet marketing. Digital marketing may also include social media
            marketing or search engine optimization (SEO).
          </p>
          <div className="service_list_container">
            <div className="list">
              <div className="icon">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 0C4.93484 0 0 4.93484 0 11C0 17.0652 4.93484 22 11 22C17.0652 22 22 17.0652 22 11C22 4.93484 17.0652 0 11 0ZM17.1479 8.10526L10.1178 15.0802C9.70426 15.4937 9.04261 15.5213 8.6015 15.1078L4.8797 11.7168C4.4386 11.3033 4.41103 10.614 4.79699 10.1729C5.21053 9.73183 5.89975 9.70426 6.34085 10.1178L9.29073 12.8195L15.5764 6.53383C16.0175 6.09273 16.7068 6.09273 17.1479 6.53383C17.589 6.97494 17.589 7.66416 17.1479 8.10526Z"
                    fill="#0C71C3"
                  />
                </svg>
              </div>
              <p>
                Digital marketing allows a company to connect with customers
                where traditional methods can’t.
              </p>
            </div>
            <div className="list">
              <div className="icon">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 0C4.93484 0 0 4.93484 0 11C0 17.0652 4.93484 22 11 22C17.0652 22 22 17.0652 22 11C22 4.93484 17.0652 0 11 0ZM17.1479 8.10526L10.1178 15.0802C9.70426 15.4937 9.04261 15.5213 8.6015 15.1078L4.8797 11.7168C4.4386 11.3033 4.41103 10.614 4.79699 10.1729C5.21053 9.73183 5.89975 9.70426 6.34085 10.1178L9.29073 12.8195L15.5764 6.53383C16.0175 6.09273 16.7068 6.09273 17.1479 6.53383C17.589 6.97494 17.589 7.66416 17.1479 8.10526Z"
                    fill="url(#paint0_linear_1168_6143)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1168_6143"
                      x1="11.0442"
                      y1="36.0161"
                      x2="14.768"
                      y2="-0.248163"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#A72BFA" />
                      <stop offset="0.283122" stop-color="#EF1380" />
                      <stop offset="0.800386" stop-color="#FF3838" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <p>
                It helps you stay connected with customers by promoting your
                brand via various digital channels such as Facebook, Instagram,
                Twitter, Linkedin, Youtube etc.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* section button start herr  */}
      <div className="dsection_top dsection_button">
        <div className="dsection_right">
          <h1>SEO can increase your website traffic</h1>
          <p>
            SEO is the most powerful strategy to improve online visibility. We
            help you to get positions in the search engine results organically.
          </p>
          <div className="service_list_container">
            <div className="blist">
              <div className="icon">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 0C4.93484 0 0 4.93484 0 11C0 17.0652 4.93484 22 11 22C17.0652 22 22 17.0652 22 11C22 4.93484 17.0652 0 11 0ZM17.1479 8.10526L10.1178 15.0802C9.70426 15.4937 9.04261 15.5213 8.6015 15.1078L4.8797 11.7168C4.4386 11.3033 4.41103 10.614 4.79699 10.1729C5.21053 9.73183 5.89975 9.70426 6.34085 10.1178L9.29073 12.8195L15.5764 6.53383C16.0175 6.09273 16.7068 6.09273 17.1479 6.53383C17.589 6.97494 17.589 7.66416 17.1479 8.10526Z"
                    fill="#0C71C3"
                  />
                </svg>
              </div>
              <div className="blist_container">
                <h3>On page SEO</h3>
                <p>
                  On page SEO helps Google better understand your website and
                  improve your Website rankings. It refers to the way you
                  structure your content and keywords.
                </p>
              </div>
            </div>
            <div className="blist">
              <div className="icon">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 0C4.93484 0 0 4.93484 0 11C0 17.0652 4.93484 22 11 22C17.0652 22 22 17.0652 22 11C22 4.93484 17.0652 0 11 0ZM17.1479 8.10526L10.1178 15.0802C9.70426 15.4937 9.04261 15.5213 8.6015 15.1078L4.8797 11.7168C4.4386 11.3033 4.41103 10.614 4.79699 10.1729C5.21053 9.73183 5.89975 9.70426 6.34085 10.1178L9.29073 12.8195L15.5764 6.53383C16.0175 6.09273 16.7068 6.09273 17.1479 6.53383C17.589 6.97494 17.589 7.66416 17.1479 8.10526Z"
                    fill="url(#paint0_linear_1168_6143)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1168_6143"
                      x1="11.0442"
                      y1="36.0161"
                      x2="14.768"
                      y2="-0.248163"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#A72BFA" />
                      <stop offset="0.283122" stop-color="#EF1380" />
                      <stop offset="0.800386" stop-color="#FF3838" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div className="blist_container">
                <h3>Off page SEO</h3>
                <p>
                  One of the most important aspects of SEO is off page SEO which
                  involves several activities to achieve results.
                </p>
              </div>
            </div>
            <div className="blist">
              <div className="icon">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 0C4.93484 0 0 4.93484 0 11C0 17.0652 4.93484 22 11 22C17.0652 22 22 17.0652 22 11C22 4.93484 17.0652 0 11 0ZM17.1479 8.10526L10.1178 15.0802C9.70426 15.4937 9.04261 15.5213 8.6015 15.1078L4.8797 11.7168C4.4386 11.3033 4.41103 10.614 4.79699 10.1729C5.21053 9.73183 5.89975 9.70426 6.34085 10.1178L9.29073 12.8195L15.5764 6.53383C16.0175 6.09273 16.7068 6.09273 17.1479 6.53383C17.589 6.97494 17.589 7.66416 17.1479 8.10526Z"
                    fill="#0C71C3"
                  />
                </svg>
              </div>
              <div className="blist_container" >
                <h3>Technical SEO</h3>
                <p>
                  OTechnical SEO ensures that a website meets the technical
                  requirements of modern search engines, which improves organic
                  rankings.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="dsection_left">
          <img
            src={image.digimarkbuttom}
            alt="Codevillage||Software Development||Webdevelopment||UIUX design"
            className="dsection_image_buttom"
          />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Digimarking;
