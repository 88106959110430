import React from 'react';
import './index.scss';
import { image } from "../../../assets/exports/image";
import { useNavigate } from 'react-router-dom';

const Sercon = () => {
  const navigate = useNavigate();
  return (
    <div className='wrapper'>
        <div className='container_left'>
            <img src={image.service_contact} alt="CodeVillage||Web development||Software development||Graphic Design"/>
        </div>
        <div className='container_right'>
          <div className='icon_wrapper'>
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.2652 5.29822C19.5758 5.55419 20.5414 6.50216 21.4856 7.44733C22.4299 8.39251 23.3769 9.35905 23.6326 10.671M18.2652 1C20.9882 1.3028 22.7676 2.28566 24.7061 4.22367C26.6446 6.16167 28.6941 7.94567 29 10.671M26.853 21.4165V25.7148C26.8546 26.0889 27.0028 26.4466 26.853 26.7893C26.7033 27.1321 27.1284 27.611 26.853 27.8639C26.5777 28.1168 26.1336 28.8186 25.7796 28.9384C25.4255 29.0583 25.0783 28.9721 24.7061 28.9384C20.577 28.4893 16.3826 26.9023 12.8978 24.6402C9.65562 22.578 6.37011 19.2892 4.30991 16.0438C2.04215 12.5397 1.52992 8.37547 1.08947 4.22367C1.05593 3.85223 0.970543 3.50255 1.08947 3.14911C1.20839 2.79567 0.838277 3.42456 1.08947 3.14911C1.34065 2.87366 1.82214 2.22524 2.16295 2.07456C2.50376 1.92387 2.86385 2.07491 3.23643 2.07456H7.53036C8.18157 2.06814 8.11008 1.6495 8.60384 2.07456C9.0976 2.49961 9.58618 3.57819 9.67732 4.22367C9.84723 5.51322 10.2965 6.22869 10.7508 7.44733C10.9313 7.92809 10.8578 9.09413 10.7508 9.59644C10.6438 10.0988 11.1116 10.3058 10.7508 10.671L8.60384 12.8201C10.514 16.1829 12.7588 18.4299 16.1182 20.342L18.2652 19.2674C18.63 18.9063 18.8369 18.3 19.3387 18.1929C19.8405 18.0858 21.0053 18.0122 21.4856 18.1929C22.7031 18.6476 23.4178 19.0974 24.7061 19.2674C25.3579 19.3595 26.4275 19.8393 26.853 20.342C27.2786 20.8447 26.8694 20.7578 26.853 21.4165Z" stroke="#FE705A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          </div>
          <h1>Sounds Like Code Village Might Be The Right Choice For Your Business?</h1>
          <div className='bar'>

          </div>
          <h3>
          9869482916 / 9822824664
          </h3>
          <p>
          Incredible, high-performing digital solutions are just a click away - let’s build them together.
          </p>
          <div className="btn-con">
            <button className="btn" onClick= {()=> navigate('/GetinTouch/')}>Let's go</button>
          </div>
        </div>
    </div>
  )
}

export default Sercon