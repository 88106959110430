import React from 'react'
import Navbar from '../../Navbar/Navbar'
import Header from '../Header/Header'
import Nav from '../Nav/Nav'
import Footer from '../../Footer/Footer'
import Sercon from '../SerContact/Sercon'
import GraphicsCon from '../GraphicsCon/GraphicsCon'

const Graphics = () => {
  return (
       <>
        <Navbar/>
        <Header/>   
        <Nav/>
        <GraphicsCon/>
        <Sercon/>
        <Footer/>

       </>
  )
}

export default Graphics