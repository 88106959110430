

export const image={
    career: require("../images/img_career.svg").default,
    about1: require("../images/about1.svg").default,
    about2: require("../images/about2.svg").default,
    about3: require("../images/about3.svg").default,
    about4: require("../images/about4.svg").default,
    about5: require("../images/about5.svg").default,
    about6: require("../images/about6.svg").default,
    about_main1: require("../images/about_main1.svg").default,
    about_main2: require("../images/about_main2.svg").default,
    logo_figma: require("../images/card_logos/logos_figma.svg").default,
    logo_react: require("../images/card_logos/logos_react.svg").default,
    logo_js: require("../images/card_logos/logos_javascript.svg").default,
    logo_nodejs: require("../images/card_logos/logos_nodejs-icon.svg").default,
    logo_photoshop: require("../images/card_logos/logos_photoshop.svg").default,
    logo_native: require("../images/card_logos/logos_reactnative.svg").default,
    logo_flutter: require("../images/card_logos/logos_flutter.svg").default,
    logo_django: require("../images/card_logos/logos_django-icon.svg").default,
    logo_bootstrap: require("../images/card_logos/logos_bootstrap.svg").default,
    logo_tailwind: require("../images/card_logos/logos_tailwindcss-icon.svg").default,
    contact_1:require("../images/contact_1.png"),
    mail:require("../images/mail.png"),
    loaction:require("../images/location.png"),
    call:require("../images/call.png"),
    getintouch: require("../images/getintouch.svg").default,
    webdevtop: require("../images/services/web_dev_top.png"),
    service_contact: require("../images/services/servicescontact.svg").default,
    graphics: require("../images/services/graphicsdesign.svg").default,
    softdevelop:require("../images/services/softdev.svg").default,
    deve:require("../images/services/deve.jpeg"),
    appdeve:require("../images/services/appdev/appdevelopment.svg").default,
    iosapp:require("../images/services/appdev/iosapp.png"),
    andriod:require("../images/services/appdev/andriodapp.png"),
    crossapp:require("../images/services/appdev/crossplatformapp.png"),
    digimarktop:require("../images/services/dmtop.svg").default,
    digimarkbuttom:require("../images/services/dmbuttom.svg").default,
    uisection:require("../images/services/uiux/uisection.svg").default,
    uicard_image_flat:require("../images/services/uiux/uicard.png"),
    team_abid:require('../images/teamimage/team_abid.png'),
    team_anish:require('../images/teamimage/team_anish.png'),
    team_bobby:require('../images/teamimage/team_babi.png'),
    team_bijaya:require('../images/teamimage/team_bijaya.png'),
    team_giri:require('../images/teamimage/team_giriraj.png'),
    
}
