import webdesign from "../assets/images/webdesign.svg";
import webdevelopment from "../assets/images/webdevelopment.svg";
import appdevelopment from "../assets/images/appdevelopment.svg";
import graphicsdesign from "../assets/images/graphicsdesign.svg";
import softwaredevelopment from "../assets/images/softwaredevelopment.png";
import logodesign from "../assets/images/logodesign.png";
import satisfiedclients from "../assets/images/satisfiedclients.svg";
import projectslaunched from "../assets/images/projectslaunched.svg";
import launchedprojects from "../assets/images/launchedprojects.svg";
import rating from "../assets/images/rating.png";
import business from "../assets/images/business.png";
import management from "../assets/images/management.png";
import making from "../assets/images/making.png";
import accurate from "../assets/images/accurate.png";
import project from "../assets/images/project.png";
import Nirman from "../assets/images/Nirman.jpg";
import fast from "../assets/images/services/softdev/fast.png";
import cost from "../assets/images/services/softdev/cost.png";
import teams from "../assets/images/services/softdev/team.png";
import secure from "../assets/images/services/softdev/secure.png";
import flexible from "../assets/images/services/softdev/flexible.png";
import selfmanage from "../assets/images/services/softdev/selfmanage.png";
import risk from "../assets/images/services/softdev/risk.png";
import design from "../assets/images/services/softdev/design.png";
import iosapp from "../assets/images/services/appdev/iosapp.png";
import andriodapp from "../assets/images/services/appdev/andriodapp.png";
import crossplatformapp from "../assets/images/services/appdev/crossplatformapp.png";
import uicard1 from "../assets/images/services/uiux/uicard1.png";
import uicard2 from "../assets/images/services/uiux/uicard2.png";
import uicard3 from "../assets/images/services/uiux/uicard3.png";
import uicard4 from "../assets/images/services/uiux/uicard4.png";
import uicard5 from "../assets/images/services/uiux/uicard5.png";
import uiicon1 from "../assets/images/services/uiux/uiicon1.png";
import uiicon2 from "../assets/images/services/uiux/uiicon2.png";
import uiicon3 from "../assets/images/services/uiux/uiicon3.png";
import uiicon4 from "../assets/images/services/uiux/uiicon4.png";
import team_abid from "../assets/images/teamimage/team_abid.png";
import team_rakesh from "../assets/images/teamimage/team_rakesh.png";
import team_babi from "../assets/images/teamimage/team_babi.png";
import team_bijaya from "../assets/images/teamimage/team_bijaya.png";
import team_giriraj from "../assets/images/teamimage/team_giriraj.png";
const services = [
  {
    name: "Web Design",
    info: "We make customized and unique website design according to the needs of the client.",
    image: webdesign,
  },
  {
    name: "Web Development",
    info: "With the help of our expert, we create a rich and excellent performance of the website.",
    image: webdevelopment,
  },
  {
    name: "Software Development",
    info: "With a team, we develops and designs software applications to provide solutions that meet the requirement of a user.",
    image: softwaredevelopment,
  },
  {
    name: "Graphics Design",
    info: "We believe in creativity so we make a design with full illustration and photographs.",
    image: graphicsdesign,
  },
  {
    name: "App Development",
    info: "Our team of developers and designers will develop user-centric mobile applications that will help you stand-out and convert to drive your business goals.",
    image: appdevelopment,
  },
  {
    name: "Logo Design",
    info: "We use artificial design for creating a unique and effective logo with perfect fonts and icons.",
    image: logodesign,
  },
];

const projects = [
  {
    name: "Satisfied Clients",
    image: satisfiedclients,
    number: 34,
    color: "pink",
  },
  {
    name: "Project Completed",
    image: launchedprojects,
    number: 15,
    color: "red",
  },
  {
    name: "Project Launched",
    image: projectslaunched,
    number: 10,
    color: "orange",
  },
  {
    name: "Ratings",
    image: rating,
    number: "4.2/5.0",
    color: "lightGreen",
  },
];

const works = [
  {
    name: "Business Collaboration",
    info: "Business partnership is one of the best ways to expand your business",
    image: business,
    color: "#0C71C3",
    border: "#0C71C3",
  },
  {
    name: "Management System",
    info: "Recognize the distinctions between right and wrong, and what to look for",
    image: management,
    color:
      " linear-gradient(5.86deg, #A72BFA -53.1%, #EF1380 -10.33%, #FF3838 67.81%)",
    border: "#FD1D1D",
  },
  {
    name: "Making Idea",
    info: "Bring your concept to life and make it a success.",
    image: making,
    color: "linear-gradient(268.49deg, #0C71C3 9.81%, #2EA3F2 91.82%)",
    border: "rgb(12,113,195)",
  },
  {
    name: "accurate testing Process",
    info: "Step-by-step comparison testing for baseline performance evaluation",
    image: accurate,
    color: "#0C71C3",
    border: "rgb(12,113,195)",
  },
  {
    name: "Make Project successful",
    info: "Organize everything to ensure the project's effectiveness and success.",
    image: project,
    color:
      "linear-gradient(5.86deg, #A72BFA -53.1%, #EF1380 -10.33%, #FF3838 67.81%)",
    border: "#FD1D1D",
  },
];

const team = [
  {
    id: 1,
    tname: "Bobby Khadka",
    role: "Chief Executive Ofiicer",
    image: team_babi,
  },
  {
    id: 2,
    tname: "Abid Adhikari",
    role: "Cheif Technology Officer",
    image: team_abid,
  },
  {
    id: 3,
    tname: "Giriraj Thapa",
    role: "Chief Product Engineer",
    image: team_giriraj,
  },
  {
    id: 4,
    tname: "Bijaya Thapaliya",
    role: "Chief Administrative Officer",
    image: team_bijaya,
  },
  {
    id: 5,
    tname: "Rakesh Joshi",
    role: "Chief Marketing Officer",
    image: team_rakesh,
  },
];

const testimony = [
  {
    id: "01",
    tname: "Nirman Khadka",
    job: "CEO & Cofounder at Aifiverse",
    testimony:
      "Our company has worked with several IT providers in the past, but none compare to the level of service we receive from Code Village. They truly care about their clients and have helped us achieve our goals through their innovative technology solutions.",
    image: Nirman,
  },
];

const softdevdata = [
  {
    title: "Fast time to market",
    text: "With Agile development, CI/CD pipeline, DevOps approach, and a balanced mix of manual and automated testing, we can introduce needed product changes while maintaining a steady release pace (every 2-3 weeks).",
    image: fast,
  },
  {
    title: "Reduced development costs",
    text: "We deliver cost-effective products thanks to the use of scalable cloud-native architectures, ready-made components (frameworks, platforms, and services), and public APIs.",
    image: cost,
  },
  {
    title: "Transparent self-management",
    text: "We offer self-managed software development teams that let you have as little involvement in the project administration as you see convenient and guarantee quality product delivery thanks to our robust Quality Management System, backed by ISO 9001 certificate. We also provide transparency in everything we do through KPIs established collaboratively with you.",
    image: selfmanage,
  },
  {
    title: "Committed teams",
    text: "We assign our specialists to the projects with their professional interests in mind so that you get a team inspired by your product.",
    image: teams,
  },
  {
    title: "Secure cooperation",
    text: "We start cooperation with signing NDA (already at the initial negotiation stage if needed) to guarantee the confidentiality of your information. As the project unfolds, your product stays protected due to our secure IT infrastructure (with IDS/IPS, DLP, SIEM), data security practises approved by the ISO 27001 certificate, and adherence to the industry-specific standards (e.g., HIPAA, PCI DSS, GDPR).",
    image: secure,
  },
  {
    title: "Flexible outsourcing options",
    text: "We can either take over the whole development process or back up your in-house development team with a dedicated team or the staff augmentation model of outsourcing.",
    image: flexible,
  },
  {
    title: "Context-aware product design",
    text: "We are mindful of your target audience and can analyze user behavior data to plan the product’s UI and functionality.",
    image: design,
  },
  {
    title: "Risk management",
    text: "We estimate different risks (time, budget, performance) and develop a mitigation plan to address each of them.",
    image: risk,
  },
];
const product = [
  {
    title: "New product development",
    point: [
      "Analyzing the context of the product use: needs and expectations of target users, estimated target market, device preference.",
      "Feature modeling including backbone and killer features to make the product marketable.",
      "Researching applicable compliance requirements (HIPAA, FDA, PCI DSS).",
      "Planning product releases and prioritizing features.",
      "Architecting a product using an optimal approach (multi-tenancy, microservices, etc.).",
      "Designing UX and UI.",
      "Elaborating subscription plans (if needed).",
      "Delivering product releases according to the plan.",
    ],
  },
  {
    title: "Continuous product evolution",
    point: [
      "Implementing behavior analytics to spot frictions in user journeys.",
      "Planning UX and UI improvements based on behavior analytics insights and user feedback.",
      "Delivering new functional modules and features envisaged in the product roadmap.",
      "Managing technical backlog.",
      "Developing APIs to expand your product’s integration capabilities.",
      "Migrating the product to the cloud or changing a cloud provider.",
      "L1, L2 and L3 user and product support.",
    ],
  },
  {
    title: "SaaS product customization",
    point: [
      "Analyzing individual requirements of your clients.",
      "Consulting the product roadmap to check if the requirements aren’t covered by future releases.",
      "Creating custom features and UI components.",
      "Creating custom APIs for integration with clients’ systems.",
    ],
  },
  {
    title: "Market-constraint product growth",
    point: [
      "Planning and implementing new functionality as a paid product upgrade or higher subscription level.",
      "Developing an international expansion strategy.",
      "Evolving the product based on user feedback.",
    ],
  },
];

const appcard = [
  {
    title: "iOS App Development",
    text: "In addition to satisfying clients’ requirements, we also meet technical, content, and design criteria set by the App Store to ensure that solutions delivered will easily pass through the submission stage.",
    image: iosapp,
  },
  {
    title: "Android App Development",
    text: "When creating apps for Android, we always keep in mind the absence of a standard user interface, putting a lot of effort into the development of solutions that will look and feel pleasantly on a wide range of devices.",
    image: andriodapp,
  },
  {
    title: "Cross-platform App Development",
    text: "We build feature-rich cross-platform apps that provide excellent user experience and address the integration challenges with the target OS.",
    image: crossplatformapp,
  },
];

const ucard_data = [
  {
    title: "UI/UX design consulting services",
    text: "If you want to ensure that your app will be loved by end users and work for your business for decades, contact our UI/UX designers to receive a comprehensive consultation on the best ways to achieve these goals.",
    image: uicard1,
  },
  {
    title: "UI/UX research and analysis",
    text: "Whether you are planning to develop a tech solution or have some app that requires design improvements, our specialists conduct comprehensive research and analysis that will allow determining the best and safest paths for your product development or enhancement.",
    image: uicard2,
  },
];
const ucard_mini_data = [
  {
    title: "Strategy building and prototyping",
    text: "Our UI/UX designers create a strategy for design development and implementation, as well as build prototypes of your apps to allow you to make changes at this stage and eliminate the possibility of unplanned expenses.",
    image: uicard3,
  },
  {
    title: "Custom UI/UX design",
    text: "Keeping our finger on the pulse of the latest trends and shifts in UI/UX, our specialists come up with unique designs that really make a difference to your end users and contribute to a greater level of your brand identity and recognition.",
    image: uicard4,
  },
  {
    title: "UI/UX enhancement and support",
    text: "Whether it is an app developed by our teams or some third-party provider, we can take responsibility for its support to ensure your opportunity to deliver desired experience to your end users as soon as it arises.",
    image: uicard5,
  },
];

const ui_solution_card = [
  {
    title: "UI/UX for mobiles",
    text: "With respect to design guidelines set by Apple Store and Google, Emerline’s teams take advantage of the well-established design practices that allow the delivery of innovative, long-lasting designs to make your mobile apps rock the market.",
    image: uiicon1,
  },
  {
    title: "UI/UX for web",
    text: "Taking into account various usability issues, including a11y, our designers create web apps that offer the same level of satisfaction with the delivered experience to everyone.",
    image: uiicon2,
  },
  {
    title: "UI/UX for cross-platform apps",
    text: "Having vast expertise in the creation of cross-platform apps, we build designs that look great on various devices and offer pleasant user journeys regardless of the platform your app is used on.",
    image: uiicon3,
  },
  {
    title: "AR experience design",
    text: "Being always inspired by innovative technologies and doing our best to enhance the experiences they offer, our teams enthusiastically dive into AR projects to provide our clients with high-end solutions that capture lots of attention, meanwhile offering a highly intuitive, not overloaded, and pleasant user experience.",
    image: uiicon4,
  },
];

export default services;
export { projects, works, team };
export { testimony };
export { softdevdata };
export { product };
export { appcard };
export { ucard_data };
export { ucard_mini_data };
export { ui_solution_card };
