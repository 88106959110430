import React from "react";
import "./adminheader.scss";

function AdminHeader(props) {
  return (
    <div className="admin_header">
      <div className="personal-section">
        <div className="image">.</div>
      </div>
    </div>
  );
}

export default AdminHeader;
