import React from "react";
// import Button from "../Shared/Button/Button";
import "./index.scss";
import logo from "../../assets/images/logo.svg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useRef } from "react";
import {HashLink} from "react-router-hash-link";
function Navbar(props) {
  const navigate = useNavigate();
  const [overlay, setOverlay] = useState(false);
  const navIcon = useRef();
  const nav = useRef();
  const mobileNav = useRef();
  const toggleClass = () => {
    !overlay
      ? (document.querySelector(":root").style.overflowY = "hidden")
      : (document.querySelector(":root").style.overflowY = "visible");
    navIcon.current.classList.toggle("open");
    setOverlay(!overlay);
  };

  return (
    <>
      <div className="navbar-container">
        <div className="navbar-wrapper">
          <div className="navbar-logo">
            <img src={logo} alt="" className="logoimgcv" />
            <h1 onClick={() => navigate("/")} className="logotitlecv">Code Village</h1>
          </div>
          <ul className="navbar-menu" id="navbar-menu" ref={nav}>
            <li className="navbar-menu-item" onClick={() => navigate("/")}>
              Home
            </li>
            <li
              className="navbar-menu-item"
              onClick={() => navigate("/Services/")}
            >
              Services
            </li>
            <li
              className="navbar-menu-item"
              onClick={() => navigate("/About/")}
            >
              About
            </li>
            <HashLink smooth to="/#team" className="hashlink">

            <li className="navbar-menu-item">Team</li>
            </HashLink>
            <li
              className="navbar-menu-item"
              onClick={() => navigate("/careers/")}
            >
              Careers
            </li>
          </ul>
          <div className="navbar-contact">
            <button
              className="contact-button"
              onClick={() => navigate("/Contact/")}
            >
              Contact Us
            </button>
          </div>
          <div id="nav-icon" ref={navIcon} onClick={toggleClass}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          {overlay && (
            <div className="menu">
              <div
                className="navbar-menu-small"
                id="navbar-menu-small"
                ref={mobileNav}
              >
                <li
                  className="navbar-menu-item"
                  onClick={() => {
                    toggleClass();
                    navigate("/");
                  }}
                >
                  Home
                </li>
                <li
                  className="navbar-menu-item"
                  onClick={() => {
                    toggleClass();
                    navigate("/Services/");
                  }}
                >
                  Services
                </li>
                <li
                  className="navbar-menu-item"
                  onClick={() => {
                    toggleClass();
                    navigate("/About/");
                  }}
                >
                  About
                </li>
                <HashLink smooth to="/#team" className="hashlink">
                <li className="navbar-menu-item"
                 onClick={()=>{
                  toggleClass();
                 }}>Team</li>
                </HashLink>
                <li
                  className="navbar-menu-item"
                  onClick={() => {
                    toggleClass();
                    navigate("/careers/");
                  }}
                >
                  Careers
                </li>
                <li
                  className="navbar-menu-item"
                  onClick={() => {
                    toggleClass();
                    navigate("/Contact/");
                  }}
                >
                  Contact
                </li>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Navbar;
