import React, { useEffect } from 'react'
import Navbar from '../../components/Navbar/Navbar';
import Mainsection from './mainsection/Mainsection';
import Vacancy from './vacancy/Vacancy';
import "./careers.scss"
import Footer from '../../components/Footer/Footer';

const Careers = () => {
    // nav-bar should  include here.
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>

            <Navbar />
            <Mainsection />
            <Vacancy />
            {/* <div className='test'></div> */}
            <Footer />
        </>
    )
};

export default Careers;