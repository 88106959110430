import React from "react";
import {useState,useEffect} from "react"
import { useNavigate} from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./index.scss";


const Nav = () => {
  const[activeLink, setActiveLink]=useState();
  const location = useLocation();
  useEffect(()=>{
    setActiveLink(location.pathname);
  },[location])
  const navigate = useNavigate();
  return (
    <>
      <div className="service_nav_wrapper">
        <ul className="service_nav" id="services_nav">
          <li className={["services_nav_item ", ...activeLink ==='/Services/'?"active":''].join('')}
           onClick={()=>navigate("/Services/")}>Web Development</li>
          <li className={["services_nav_item ", ...activeLink ==='/Services/Graphics'?"active":''].join('')}
           onClick={()=>navigate("/Services/Graphics")}>Graphics Design</li>
          <li className={["services_nav_item ", ...activeLink ==='/Services/Software_Development'?"active":''].join('')} 
          onClick={()=>navigate("/Services/Software_Development")}>Software Development</li>
          <li className={["services_nav_item ", ...activeLink ==='/Services/AppDevelopment'?"active":''].join('')}
           onClick={()=>navigate("/Services/AppDevelopment")}>App Development</li>
          <li className={["services_nav_item ", ...activeLink ==='/Services/Digitalmarketing'?"active":''].join('')}
           onClick={()=>navigate("/Services/Digitalmarketing")}>Digital Marketing</li>
          <li className={["services_nav_item ", ...activeLink ==='/Services/UIUXdesign'?"active":''].join('')}
           onClick={()=>navigate("/Services/UIUXdesign")}>UI/UX Design</li>
        </ul>
      </div>
      <div className="space"></div>
    </>
  );
};

export default Nav;
