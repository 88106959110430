import React from "react";
import Home from "./pages/Home/Home";
import "./styles/global.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Careers from "./pages/Careers/careers";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import GetinTouch from "./pages/GetinTouch/GetinTouch";
import Services from "./pages/Services/Services";
import Dashboard from "./pages/Admin/Dashboard/Dashboard";
import Graphics from "./components/Service_pages/Graphics/Graphics";
import Softdev from "./components/Service_pages/SoftwareDev/Softdev";
import Appdev from "./components/Service_pages/Appdev/Appdev"
import Digimarking from "./components/Service_pages/Digimark/Digimarking";
import Uiux from './components/Service_pages/UIUX/Uiux';

function App(props) {
  document.querySelector(":root").style.overflowX = "hidden";
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/getintouch" element={<GetinTouch />} />
          <Route path="/services" element={<Services/>}/>
          <Route path="/services/graphics" element ={<Graphics/>}/>
          <Route path="/services/software_development" element={<Softdev/>}/>
          <Route path="/services/AppDevelopment" element={<Appdev/>}/>
          <Route path ="/Services/Digitalmarketing" element={<Digimarking/>}/>
          <Route path="/Services/UIUXdesign" element={<Uiux/>}/>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="*" element={<h1>k ho vai</h1>} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
