import React from 'react'
import Card from './Card'
import './index.scss'
import Procard from './Procard'

const Product = () => {
  return (
    <>
    <div className='title'>
        <h1>
        How You Win with Product Development Outsourcing
        </h1>
        <div className='bar'>

        </div>
    </div>
    <div className='scardwrapper'>
    <Card/>
    </div>

    <div className='title'>
      <h1>
      We Work with Product Startups and Mature Product Companies 
      </h1>
      <div className='bar'>

      </div>
    </div>
    <div className='scardwrapper'>
      <Procard/>
    </div>
    
    </>
  )
}

export default Product