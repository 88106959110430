import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Aboutus from "../../components/Aboutsection/Aboutus";
import Description from "../../components/Aboutsection/Description";
import Footer from "../../components/Footer/Footer"

const About = () =>{
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
    <>
    <Navbar/>
        
    <Aboutus/>
    <Description />
    <Footer/>

    </>
    )
};

export default About;