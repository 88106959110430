import React, { useEffect } from "react";
import "./contact.scss";
import Navbar from "../../components/Navbar/Navbar";
import Form from "../../components/Form/Form";
import Footer from "../../components/Footer/Footer";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <section className="contact-wrapper">
        <div className="contact_wrapper_child">
          <div className=" contact_decrp">
            <h1>
              Innovation <span>Starts Here!</span>
            </h1>
            <h1>
              Book a <span>Free Consultation</span> Now.
            </h1>
            <p>
              Incredible, high-performing digital solutions are just a click
              away - let’s build them together.
            </p>
          </div>

          <div className="contact_form_wrapper">
            <div className="img_container">
              <div className="img_container-text">
                <h3>What's on your mind?</h3>
                <h5>Share with our experts.</h5>
              </div>
            </div>
            <div className="form_container">
              <div className="form_wrapper">
                <h4>Contact Directly</h4>
                <div className="contact_handle">
                  <div className="contact_icon">
                    <svg
                      width="26"
                      height="25"
                      viewBox="0 0 26 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.6821 4.53068C16.7587 4.74094 17.5519 5.51963 18.3275 6.29602C19.1031 7.07242 19.881 7.86636 20.0911 8.94403M15.6821 1C17.9189 1.24873 19.3805 2.05608 20.9728 3.64801C22.5652 5.23994 24.2487 6.70537 24.5 8.94403M22.7364 17.7707V21.3014C22.7377 21.6087 22.8594 21.9025 22.7364 22.1841C22.6134 22.4656 22.9626 22.859 22.7364 23.0668C22.5102 23.2745 22.1454 23.851 21.8546 23.9494C21.5638 24.0479 21.2786 23.9771 20.9728 23.9494C17.5811 23.5805 14.1357 22.2769 11.2732 20.4187C8.60998 18.7248 5.91116 16.0232 4.21886 13.3574C2.35605 10.479 1.93529 7.05842 1.57349 3.64801C1.54595 3.3429 1.4758 3.05567 1.57349 2.76534C1.67118 2.47501 1.36716 2.9916 1.57349 2.76534C1.77982 2.53908 2.17533 2.00645 2.45528 1.88267C2.73523 1.75889 3.03102 1.88296 3.33707 1.88267H6.86422C7.39914 1.8774 7.34042 1.53351 7.74601 1.88267C8.1516 2.23183 8.55293 3.1178 8.6278 3.64801C8.76737 4.70729 9.13643 5.29499 9.50959 6.29602C9.65789 6.69093 9.5975 7.64875 9.50959 8.06136C9.42168 8.47397 9.80594 8.64405 9.50959 8.94403L7.74601 10.7094C9.31511 13.4716 11.159 15.3174 13.9185 16.8881L15.6821 16.0054C15.9818 15.7087 16.1517 15.2107 16.5639 15.1227C16.9761 15.0347 17.933 14.9743 18.3275 15.1227C19.3275 15.4963 19.9146 15.8657 20.9728 16.0054C21.5083 16.081 22.3869 16.4751 22.7364 16.8881C23.086 17.301 22.7498 17.2296 22.7364 17.7707Z"
                        stroke="#2EA3F2"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <p>9869482916</p>
                  </div>
                  <div className="contact_icon">
                    <svg
                      width="28"
                      height="22"
                      viewBox="0 0 28 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.5 1H24.5C25.9208 1 26.5 1.57917 26.5 3V19C26.5 20.4208 25.9208 21 24.5 21H3.5C2.07917 21 1.5 20.4208 1.5 19V3C1.5 1.57917 2.07917 1 3.5 1Z"
                        stroke="#2EA3F2"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M26.5 3L13.5 12L1.5 3"
                        stroke="#2EA3F2"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <p>contact@codevillage.com.np</p>
                  </div>
                  <div className="contact_icon">
                    <svg
                      width="21"
                      height="25"
                      viewBox="0 0 21 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.875 10.4167C19.875 17.7084 10.5 23.9584 10.5 23.9584C10.5 23.9584 1.125 17.7084 1.125 10.4167C1.125 7.93034 2.11272 5.54578 3.87087 3.78762C5.62903 2.02947 8.0136 1.04175 10.5 1.04175C12.9864 1.04175 15.371 2.02947 17.1291 3.78762C18.8873 5.54578 19.875 7.93034 19.875 10.4167Z"
                        stroke="#2EA3F2"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.5 13.5417C12.2259 13.5417 13.625 12.1426 13.625 10.4167C13.625 8.69086 12.2259 7.29175 10.5 7.29175C8.77411 7.29175 7.375 8.69086 7.375 10.4167C7.375 12.1426 8.77411 13.5417 10.5 13.5417Z"
                        stroke="#2EA3F2"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <p>Gatthaghar,Bhaktapur</p>
                  </div>
                </div>
                <Form />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="contact-footer">
        <Footer />
      </div>
    </>
  );
};
export default Contact;
