import React from "react";
import "../../pages/About/about.scss";
import { image } from "../../assets/exports/image";




const Aboutus = () => (
    <>
        <div className="about_us_container">
            <div className="about_content">
                <h1 className="title_about">
                    about us
                </h1>
            </div>
            <div className="card_container">
                <div className="card1 card_about">
                    <div className="text_content">
                        <h3>
                            <h2 className="ltext orange">
                                A </h2>
                            <span className="orange">
                                years
                            </span> on</h3><h3>

                            the market
                        </h3>
                    </div>
                </div>
                <div className="card_about card2">
                    <div className="text_content">
                        <h3>
                            <h2 className="ltext orange">
                                25+
                            </h2><br />
                            <span className="orange">
                                accomplished Apps
                            </span><br /></h3>
                            <h3>
                            in 16 niches
                        </h3>
                    </div>
                </div>
                <div className="card3 card_about">
                    <div className="text_content">
                        <h3>
                            <h2 className="ltext orange">
                                58%
                            </h2><br />
                            annual growth
                        </h3>
                    </div>
                </div>
                <div className="card4 card_about">
                <div className="text_content">
                    <h3>
                        <h2 className="ltext orange">
                            10+
                        </h2><br />
                        in_house engineers.
                    </h3>
                    <h3>
                        <h2 className="ltext orange">
                            29%
                        </h2><br />
                        Senior + level.
                    </h3>
                    </div>
                </div>
                <div className="card5 card_about">
                    <div className="text_content">
                    <h3>We are expert in:</h3>
                    </div>
                    <div className="logo_wrapper">
                        <img src={image.logo_figma} alt="ui/ux design" className="card_logo"/>
                        <img src={image.logo_react} alt="codevillage" className="card_logo"/>
                        <img src={image.logo_js} alt ="codeVillage" className="card_logo"/>
                        <img src={image.logo_nodejs} alt ="codeVillage" className="card_logo"/>
                        <img src={image.logo_photoshop} alt ="codeVillage" className="card_logo"/>
                        <img src={image.logo_native} alt ="codeVillage" className="card_logo"/>
                        <img src={image.logo_flutter} alt ="codeVillage" className="card_logo"/>
                        <img src={image.logo_django} alt ="codeVillage" className="card_logo"/>
                        <img src={image.logo_bootstrap} alt ="codeVillage" className="card_logo"/>
                        <img src={image.logo_tailwind} alt ="codeVillage" className="card_logo"/>
                    </div>
                </div>
                <div className="card6 card_about">
                    <div className="text_content">
                        <h3>
                            Intership 
                            <span className="orange"> Programs</span>
                        </h3>
                        <p className="card_para">
                        An internship at Code Village for the desiger and developer pays very well. Software.
                        </p>
                    </div>
                </div>
            </div>
        </div>


    </>
);
export default Aboutus;