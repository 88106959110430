import React from 'react'
import { softdevdata} from '../../../data/info'
import "./index.scss"

const Card = () => {
  return (
    <>
      {softdevdata.map(({title,text,image})=>(
        <div className='scard_container'>
          <div className='icon_container'>
            <img src={image} alt ="codevillage"/>
          </div>
          <h3>
            {title}
          </h3>
          <p>
            {text}
          </p>
        </div>
      )
      
      )}  
    </>
  )
}

export default Card