import React from "react";
import { product } from "../../../data/info";
import './index.scss'

const Procard = () => {
  return (
    <>
      {product.map(({ title, point }) => (
        <div className="scard_container">
          <h3 className="pro_title">{title}</h3>
          <div>
            {point.map((point, index) => {
              return (
                <div key={index} className="service_product_list">
                  <div className="bullets">
                    <svg
                      width="8"
                      height="9"
                      viewBox="0 0 8 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect y="0.78125" width="8" height="8" fill="#0C71C3" />
                    </svg>
                  </div>
                  <p>{point}</p>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </>
  );
};

export default Procard;
