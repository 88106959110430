import React from "react";
import "./index.scss";
import logo from "../../assets/images/logo.svg";
import { useNavigate } from "react-router-dom";

function Footer(props) {
  const navigate = useNavigate();
  const openFacebook = () => {
    window.open("https://www.facebook.com/officialcodevillage");
  };
  const openTweeter = () => {
    window.open("#");
  };
  const openLinkdin = () => {
    window.open("https://www.linkedin.com/company/officialcodevillage/");
  };
  const openGithub = () => {
    window.open("https://github.com/codevillage-pvt-ltd/codevillage");
  };
  return (
    <footer className="footer_wrapper contact_extra_padding">
      {/* <h3>{props}</h3> */}
      <section className="footer-section">
        <div className="first_section">
          <h1>Start your project with our specialists</h1>
          <div className="btn_wrapper">
            <div className="footer-contact">
              <button
                className="contact-button"
                onClick={() => navigate("/GetinTouch/")}
              >
                Let's Work Together
              </button>
            </div>
          </div>
        </div>
        <hr className="divder" />
        <div className="second_section">
          <div className="social_links">
            <div className="navbar-logo logo_container">
              <img src={logo} alt="" className="logoimgcv" />
              <h1 onClick={() => navigate("/")} className="logotitlecv">
                Code Village
              </h1>
            </div>
            <div className="footer_about">
              <p>
                Code Village is a digital firm that offers tech service such Web
                Design & Development, Graphic Design, Digital SEO and other
                services.{" "}
              </p>
            </div>
            <div className="social_link_container">
              <div className="icon" onClick={openFacebook}>
                <svg
                  width="10"
                  height="20"
                  viewBox="0 0 10 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10 7.69231H6.66667V4.61538C6.66667 3.76615 7.264 3.84615 8 3.84615H9.33333V0H6.66667C5.6058 0 4.58838 0.486263 3.83824 1.35181C3.08809 2.21737 2.66667 3.39131 2.66667 4.61538V7.69231H0V11.5385H2.66667V20H6.66667V11.5385H8.66667L10 7.69231Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="icon" onClick={openTweeter}>
                <svg
                  width="20"
                  height="15"
                  viewBox="0 0 20 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 1.77672C19.2477 2.07961 18.452 2.28092 17.6375 2.37445C18.4959 1.90435 19.1376 1.15994 19.4412 0.282291C18.6376 0.722162 17.7585 1.03196 16.8418 1.19829C16.2797 0.643876 15.5498 0.258402 14.7471 0.0919881C13.9443 -0.0744262 13.106 -0.0140825 12.3409 0.265173C11.5758 0.544429 10.9195 1.02968 10.4572 1.65783C9.99495 2.28598 9.74811 3.02797 9.7488 3.78734C9.74565 4.07711 9.77753 4.3663 9.84384 4.64955C8.21268 4.57574 6.61677 4.185 5.16043 3.50288C3.70409 2.82076 2.42012 1.86261 1.39245 0.691089C0.864844 1.52361 0.701343 2.51059 0.935321 3.4506C1.1693 4.3906 1.78312 5.21275 2.65149 5.74923C2.00274 5.73305 1.3677 5.57318 0.799894 5.28308V5.32432C0.800962 6.19826 1.1282 7.04519 1.72653 7.72254C2.32485 8.39988 3.15773 8.86628 4.08496 9.04323C3.73418 9.12858 3.37266 9.17049 3.00991 9.16788C2.74943 9.17212 2.48922 9.15044 2.23377 9.10321C2.49877 9.85431 3.00949 10.5111 3.69562 10.9832C4.38176 11.4554 5.20957 11.7196 6.0651 11.7395C4.61377 12.7871 2.82387 13.3554 0.981439 13.3537C0.653423 13.3557 0.325605 13.3383 0 13.3016C1.87484 14.4167 4.05953 15.0066 6.2899 14.9999C13.8348 14.9999 17.96 9.23123 17.96 4.23082C17.96 4.06344 17.9537 3.90205 17.9449 3.74161C18.7537 3.2074 19.4501 2.54155 20 1.77672Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="icon" onClick={openLinkdin}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20 20H15.5796V12.969C15.5796 11.129 14.8886 9.8725 13.3677 9.8725C12.205 9.8725 11.5582 10.7205 11.2573 11.5375C11.1441 11.8305 11.1618 12.239 11.1618 12.6475V20H6.78273C6.78273 20 6.83909 7.5455 6.78273 6.4135H11.1618V8.54601C11.4209 7.61351 12.82 6.283 15.0527 6.283C17.8232 6.283 20 8.237 20 12.4455V20ZM2.35454 4.714H2.32591C0.915002 4.714 0 3.67549 0 2.35899C0 1.01699 0.941824 0 2.38137 0C3.81955 0 4.70408 1.0145 4.73181 2.355C4.73181 3.6715 3.81954 4.714 2.35454 4.714ZM0.504539 6.4135H4.40272V20H0.504539V6.4135Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="icon" onClick={openGithub}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12 0C5.37 0 0 5.37 0 12C0 17.31 3.435 21.795 8.205 23.385C8.805 23.49 9.03 23.13 9.03 22.815C9.03 22.53 9.015 21.585 9.015 20.58C6 21.135 5.22 19.845 4.98 19.17C4.845 18.825 4.26 17.76 3.75 17.475C3.33 17.25 2.73 16.695 3.735 16.68C4.68 16.665 5.355 17.55 5.58 17.91C6.66 19.725 8.385 19.215 9.075 18.9C9.18 18.12 9.495 17.595 9.84 17.295C7.17 16.995 4.38 15.96 4.38 11.37C4.38 10.065 4.845 8.985 5.61 8.145C5.49 7.845 5.07 6.615 5.73 4.965C5.73 4.965 6.735 4.65 9.03 6.195C9.99 5.925 11.01 5.79 12.03 5.79C13.05 5.79 14.07 5.925 15.03 6.195C17.325 4.635 18.33 4.965 18.33 4.965C18.99 6.615 18.57 7.845 18.45 8.145C19.215 8.985 19.68 10.05 19.68 11.37C19.68 15.975 16.875 16.995 14.205 17.295C14.64 17.67 15.015 18.39 15.015 19.515C15.015 21.12 15 22.41 15 22.815C15 23.13 15.225 23.505 15.825 23.385C18.2072 22.5807 20.2772 21.0497 21.7437 19.0074C23.2101 16.965 23.9993 14.5143 24 12C24 5.37 18.63 0 12 0Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="links">
            <div className="f_company sub">
              <h2>Company</h2>
              <ul>
                <li className="f_links">Service</li>
                <li className="f_links">About us</li>
                <li className="f_links">Partners</li>
                <li className="f_links">Careers</li>
                <li className="f_links">Pricing</li>
                <li className="f_links">Testimonials</li>
              </ul>
            </div>
            <div className="f_resource sub">
              <h2>Resourse</h2>
              <ul>
                <li className="f_links">Technologies</li>
                <li className="f_links">Terms and Conditions</li>
                <li className="f_links">Privacy Policy</li>
                <li className="f_links">Team</li>
              </ul>
            </div>
            <div className="f_services sub">
              <h2>Services</h2>
              <ul>
                <li className="f_links">Ecommerce Platforms</li>
                <li className="f_links">Websits and Web Apps</li>
                <li className="f_links">Content Platforms</li>
                <li className="f_links">Strategy & Design</li>
                <li className="f_links">Digital Products</li>
              </ul>
            </div>
            <div className="f_address sub">
              <h2>Address</h2>
              <ul>
                <li className="f_links">Email:contact@codevillage.com.np</li>
                <li className="f_links">Phone: +977 9869482916</li>
                <li className="f_links">
                  Location: Madhyapur Thimi, Bhaktapur
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="divder" />
        <div className="third_section">
          <p>
            Copyright © {new Date().getFullYear()} <span>Code Village.</span>{" "}
            All rights reserved.
          </p>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
