import React from "react";
import logo from "../../../assets/images/logo.svg";
import { Link } from "react-router-dom";
import {
  Grid,
  Mail,
  Users,
  FileText,
} from "feather-icons-react/build/IconComponents";

function AdminSideBar(props) {
  return (
    <>
      <div className="logo">
        <img src={logo} alt="imgggg" />
      </div>
      <ul className="dashlink_container">
        <li className="dashlink">
          <Link
            to="/dashboard"
            className={({ isActive }) =>
              isActive ? "active-dash-link " : undefined
            }
          >
            <div className="icon">
              <Grid size={14} />
            </div>
            Dashboard
          </Link>
        </li>
        <li className="dashlink">
          <Link to="/dashboard">
            <div className="icon">
              <FileText size={14} />
            </div>
            Logs
          </Link>
        </li>
        <li className="dashlink">
          <Link to="/dashboard">
            <div className="icon">
              <Users size={14} />
            </div>
            Carrers
          </Link>
        </li>
        <li className="dashlink">
          <Link to="/dashboard">
            <div className="icon">
              <Mail size={14} />
            </div>
            Message
          </Link>
        </li>
      </ul>
    </>
  );
}

export default AdminSideBar;
