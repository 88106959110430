import React from "react";
import "../../pages/About/about.scss";
import { image } from "../../assets/exports/image.js";

const Description = () => (
    <>
        {/* this is about section.  */}
        <div className="about_mainsection">
            <div className="section_first first">
                <div className="mainsection_image">
                    <img src={image.about_main1} alt="codeVillage||website development||app development||software development" />
                </div>
                <div className=" mainsection_aboutcontainer">
                    <div className=" mainsection_title">
                        <h3 className="title_abtcompany">
                            About Company
                        </h3>
                        <h2 className="about_subheading">
                            We have the skills to handle projects with ease
                        </h2>
                        <p className="para">
                            Code Village is a digital firm that offers tech services to both domestic and international clients. For our clients, we provide Digital SEO, Web Design & Development, Graphic Design, and other services.
                        </p>
                        <p className="para">
                            Code Village has a good understanding of client needs and satisfaction. We work in a transparent manner with clear goals in mind, and this should be reflected in our customer relationships. This clarity inspires us to be more serious about our objectives. By utilizing the power of the Internet, we assist businesses in fast gaining more clients and sales. Our team of digital marketing experts has the knowledge, creativity, and experience to help your company achieve outstanding results.
                        </p>
                    </div>
                </div>

            </div>
            <div className="section_first second">
                <div className=" mainsection_aboutcontainer">
                    <div className=" mainsection_title">
                        <h3 className="title_abtcompany">
                            Our Mission & Vision
                        </h3>
                        <h2 className="about_subheading">
                            the agility to manage tasks quickly and efficiently
                        </h2>
                        <p className="para">
                            Code Village is a technology company whose mission is to digitize a company’s working environment in order to achieve greater success. We strive to provide global opportunities, growth, and impact on a local and worldwide level. Our objective is to provide world-class digital services while also encouraging creativity and productivity across the country.
                        </p>
                        <p className="para">
                            Our firm creates unique online solutions that improve the efficiency and effectiveness of your work for your organization and, as a result, for your end customers. Several changes have occurred in the tech business and the way people work, according to our observations. We believe that these transitions should function as a catalyst for business and technology leaders to address their enterprises’ myriad difficulties.
                        </p>
                    </div>
                </div>
                <div className="mainsection_image">
                    <img src={image.about_main2} alt="codeVillage||website development||app development||software development" />
                </div>

            </div>
        </div>


    </>
);



export default Description;