import React from 'react'
import { ucard_data } from '../../../data/info'
const Ucard = () => {
  return (
    <>
    {ucard_data.map(({title, text, image})=>(
        <div className='ucard_container'>
            <div className='image_container'>
                <img src={image} alt="Codevillage||UIUX design"/>
            </div>
            <div className='ucard_contain'>
            <h3>{title}</h3>
            <p>{text}</p>
            </div>
        </div>
    ))}
    </>
  )
}

export default Ucard