import React from "react";
import { Solutioncards } from "./Solutioncards";
import "./index.scss";

const Solution = () => {
  return (
    <>
      <div className="solution-wrapper">
        <div className="solution-container">
          <div className="solution-container-headwrapper">
            <h3 className="solution-container-heading">
              Solutions Covered by UI/UX Design Services
            </h3>
          </div>

          <p className="solution-container-para">
            UI/UX designers at Emerline apply their expertise to various types
            of solutions, including but not limited to:
          </p>
        </div>
        <div className="solution-card-wrapper">
          <Solutioncards />
        </div>
      </div>
    </>
  );
};

export default Solution;
