import React from 'react'
import './index.scss'
export default function Service(props) {
  return (
    <>
        <div className="service">
            <div className="service-image">
                <img src= {props.image} alt="" />
            </div>
            <div className="service-name">
                <h2>{props.name}</h2>
            </div>
            <div className="service-info">
                <p>{props.info}</p>
            </div>
        </div>
    </>
  )
}
