export const data_card=[
    {
        position:'Software QA',
        func_title:'Developer Team',
        corp_title:'Developer Team',
        no_of_vaccant:1,
        post_date:"Oct 19, 2022",

    },
    {
        position:'Frontend Developer',
        func_title:'Developer Team',
        corp_title:'Developer Team',
        no_of_vaccant:3,
        post_date:"Oct 19, 2022",

    },
    {
        position:'Sale Officer',
        func_title:'Marketing Team',
        corp_title:'Marketing Member',
        no_of_vaccant:1,
        post_date:"Oct 19, 2022",

    },
    {
        position:'Senior Software Engineer',
        func_title:'Developer Team',
        corp_title:'Technical Officer',
        no_of_vaccant:1,
        post_date:"Oct 19, 2022",

    },
    {
        position:'Accountant',
        func_title:'Marketing Team',
        corp_title:'Marketing Member',
        no_of_vaccant:1,
        post_date:"Oct 19, 2022",

    }


]