import React from 'react'
import { image } from '../../../assets/exports/image'
import "./index.scss"
import Card from './Card'

const GraphicsCon = () => {
  return (
    <>
    <div className ="gsection">
        <div className='gleft'>
                <img src={image.graphics} alt ="CodeVillage||Graphics Design||Software Development||SEO||App Development"/>
        </div>
        <div className='gright'>
            <h1>Creative Design Is The Catalyst For Better Business.</h1>
            <p>Design plays a critical role in driving value and improving the customer experience. It can help you improve your business, drive growth, and innovate new ideas. Creative design is a key ingredient in the growth of any business.</p>
            <div className='card_contain'>
                <Card/>
            </div>
        </div>
    </div>
    </>
  )
}

export default GraphicsCon