import React from 'react';
import "../careers.scss";
import { data_card } from "./data";

const Card = () => {
    return (
        <>
            {
                data_card.map(
                    ({ position, func_title, corp_title, no_of_vaccant, post_date }) => (
                        <div className='Card_container'>
                            <h3 className='vacan_position'>
                                {position}
                            </h3>
                            <h5 className='func_title'>
                                Functional Title: {func_title}
                            </h5>
                            <div className='card_info'>
                                Corporate Title: {corp_title}
                            </div>
                            <div className='card_info'>
                                 No. of Vacancies: {no_of_vaccant}
                            </div>
                            <div className='card_info'>
                                Posted date:{post_date} 
                            </div>
                            <a href= {() => false}>
                                view detail 
                            </a>
                        </div>
                    )
                )
            }
        </>
    )
}

export default Card;