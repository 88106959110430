import React, { useEffect } from "react";
import {useNavigate} from 'react-router-dom';
import Navbar from "../../components/Navbar/Navbar";
import cover from "../../assets/images/cover.png";
import services from "../../data/info";
import Service from "../../components/Service/Service";
import { projects, works } from "../../data/info";
import "./index.scss";
import Project from "../../components/Project/Project";
import Work from "../../components/Work/Work";
import Team from "../../components/Team/Team";
import rocket from "../../assets/images/rocket.svg";
import Footer from "../../components/Footer/Footer";
import Testimonial from "../../components/Testimonial/Testimonial";

export default function Home() {
  const navigate= useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      {/* <div className="dummy-container"></div> */}
      <div className="landing-cover-container">
        <div className="landing-cover-main">
          <div className="landing-cover-info">
            <h1>
              Building <br />
              Innovative Software
              <br />
              For Industry Leaders
            </h1>
            <p>Web and mobile application development</p>
            <button className="gradient-button" onClick= {()=> navigate('/GetinTouch/')}>Let's Work Together</button>
          </div>
          <div className="landing-cover-image">
            <img src={cover} alt="" />
          </div>
        </div>
      </div>
      <div className="services-wrapper">
        <div className="services-heading">
          <h3>Featured Service</h3>
          <h2>We give creative IT solutions</h2>
        </div>
        <div className="services_pre_contains">
          <div className="pre_contains">
            <svg
              width="15"
              height="11"
              viewBox="0 0 15 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.803467 5.09821L5.49097 9.65179L14.0624 0.8125"
                stroke="url(#paint0_linear_1155_820)"
                stroke-width="1.875"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1155_820"
                  x1="7.45956"
                  y1="15.2833"
                  x2="8.46283"
                  y2="0.627835"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#A72BFA" />
                  <stop offset="0.283122" stop-color="#EF1380" />
                  <stop offset="0.800386" stop-color="#FF3838" />
                </linearGradient>
              </defs>
            </svg>
            <div className="list_container">
              <h3>Get effective consultancy</h3>
              <p>
                Take our consultancy to highlight your business, and you will
                find smarter solutions for your business.
              </p>
            </div>
          </div>

          <div className="pre_contains">
            <svg
              width="15"
              height="11"
              viewBox="0 0 15 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.803467 5.09821L5.49097 9.65179L14.0624 0.8125"
                stroke="url(#paint0_linear_1155_820)"
                stroke-width="1.875"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1155_820"
                  x1="7.45956"
                  y1="15.2833"
                  x2="8.46283"
                  y2="0.627835"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#A72BFA" />
                  <stop offset="0.283122" stop-color="#EF1380" />
                  <stop offset="0.800386" stop-color="#FF3838" />
                </linearGradient>
              </defs>
            </svg>
            <div className="list_container">
              <h3>Get eye catching Content</h3>
              <p>
                Our team will create unique content for you that gets the
                attention.
              </p>
            </div>
          </div>

          <div className="pre_contains">
            <svg
              width="15"
              height="11"
              viewBox="0 0 15 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.803467 5.09821L5.49097 9.65179L14.0624 0.8125"
                stroke="url(#paint0_linear_1155_820)"
                stroke-width="1.875"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1155_820"
                  x1="7.45956"
                  y1="15.2833"
                  x2="8.46283"
                  y2="0.627835"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#A72BFA" />
                  <stop offset="0.283122" stop-color="#EF1380" />
                  <stop offset="0.800386" stop-color="#FF3838" />
                </linearGradient>
              </defs>
            </svg>
            <div className="list_container">
              <h3>Get best solution for your business</h3>
              <p>
                We make it easy for you to create, manage and optimize your
                business and achieve your business goals.
              </p>
            </div>
          </div>
        </div>

        <div className="services-container">
          {services.map((item, index) => {
            return <Service {...item} key={index} />;
          })}
        </div>
      </div>
      <div className="projects-wrapper">
        <div className="projects-container">
          {projects.map((item, index) => {
            return <Project {...item} key={index} />;
          })}
        </div>
      </div>
      <div className="works-wrapper">
        <div className="works-container">
          <div className="works-heading">
            <h3>How We Work</h3>
            <h2>Gearing Up For Your Success</h2>
            <p>
              Code Village is a one-stop destination for finding the optimal
              solution for your needs of the business.
            </p>
          </div>
          <div className="works-main">
            {works.map((item, index) => {
              return <Work {...item} key={index} pos={index + 1} />;
            })}
          </div>
        </div>
      </div>
      <div className="team-wrapper" id="team">
        <div className="team-heading">
          <h2>Our Executive Team</h2>
        </div>
        <div className="team-container">
          <Team />
        </div>
      </div>
      <div className="starting-wrapper">
        <div className="starting-container">
          <div className="starting-container-rocket">
            <img src={rocket} alt="" />
          </div>
          <div className="starting-container-right">
            <div className="starting-container-empty"></div>
            <div className="starting-container-heading">
              <h2>
                Starting a Project?
                <br />
                Let's Build Something Together
              </h2>
            </div>
            <div className="starting-container-button">
              <button onClick= {()=> navigate('/GetinTouch/')}>Let's Go</button>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonials-wrapper">
        <h1 className="testimonials_head">
          What our <span>Alliance</span> say
        </h1>
        <div className="testimonials-container">
          <Testimonial />
        </div>
      </div>
      <Footer />
    </>
  );
}
