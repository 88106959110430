import React from "react";
import { graphics_data } from "./data";
import './index.scss';

const Card = () => {
  return (
    <>
      {graphics_data.map(
        ({tag})=>(
        <div className="gcard_container">
          <div className="icon">
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 0C4.93484 0 0 4.93484 0 11C0 17.0652 4.93484 22 11 22C17.0652 22 22 17.0652 22 11C22 4.93484 17.0652 0 11 0ZM17.1479 8.10526L10.1178 15.0802C9.70426 15.4937 9.04261 15.5213 8.6015 15.1078L4.8797 11.7168C4.4386 11.3033 4.41103 10.614 4.79699 10.1729C5.21053 9.73183 5.89975 9.70426 6.34085 10.1178L9.29073 12.8195L15.5764 6.53383C16.0175 6.09273 16.7068 6.09273 17.1479 6.53383C17.589 6.97494 17.589 7.66416 17.1479 8.10526Z"
                fill="url(#paint0_linear_1157_1867)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1157_1867"
                  x1="11.0442"
                  y1="36.0161"
                  x2="14.768"
                  y2="-0.248163"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#A72BFA" />
                  <stop offset="0.283122" stop-color="#EF1380" />
                  <stop offset="0.800386" stop-color="#FF3838" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <p className="gcardp">
            {tag}
          </p>
        </div>
        )
      )}
    </>
  );
};

export default Card;
