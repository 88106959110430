import React from "react";
import { ui_solution_card } from "../../../data/info";
import "./index.scss";

export const Solutioncards = () => {
  return (
    <>
      {ui_solution_card.map(({ title, text, image }) => (
        <div className="scard-container">
          <div className="scard-container-right">
            <img src={image} alt="CodeVillage||UIUX Design||" />
          </div>
          <div className="scard-container-left">
            <div className="scard-container-heading">
              <h3>{title}</h3>
            </div>
            <p className="scard-container-text">{text}</p>
          </div>
        </div>
      ))}
    </>
  );
};
