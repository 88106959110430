import React from 'react'
import './index.scss'
export default function Work(props) {
    return (
        <>
            <div className="work">
                <div className="work-image">
                    <img src={props.image} alt="" />
                </div>
                <div className="work-divider">
                    <div className="work-divider-container">
                        <div className="work-divider-top"></div>
                        <input type="radio" checked style={{accentColor: props.border}}/>
                        <div className="work-divider-bottom"></div>
                    </div>
                </div>
                <div className="work-separator"></div>
                <div className="work-content" style={{borderLeftColor: props.border}}>
                    <div className="work-content-index" >
                        <h2 style={{background: props.color}}>0{props.pos}</h2>
                    </div>
                    <div className="work-content-desc">
                        <h2>{props.name}</h2>
                        <p>{props.info}</p>
                    </div>
                </div>
            </div>
        </>
    )
}
