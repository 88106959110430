import React from "react";
import "./index.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { team } from "../../data/info";

const Team = () => {
  const settings = {
    id: "team",
    dots: false,
    infinite: true,
    speed: 3500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      {team.map((item, index) => {
        return (
          <div key={index} className="team-card-container">
            <div className="team">
              <div className="team-image">
                <img src={item.image} alt={`Slide ${item.id}`} className="sliderimg" />
              </div>
              <div className="team-detail">
                <h4 className="team-name">{item.tname}</h4>
                <p className="team-role">{item.role}</p>
              </div>
            </div>
          </div>
        );
      })}
    </Slider>
  );
};
export default Team;
